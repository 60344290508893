import Mock from '../mock';

const database = {
    information: {
        name: 'Elena Romanenko',
        fullname: 'Elena Romanenko (Mozzherina)',
        aboutContent:
            'I am a computer scientist and a tutor. My interests lie in the field of natural language processing, machine learning and artificial intelligence.',
        phone: '',
        language: 'Russian, English, German',
        email: 'e.mozzherina@hotmail.com',
        address: 'St. Petersburg, Russia',
        freelanceStatus: 'Available',
        socialLinks: {
            facebook: 'https://www.facebook.com/elena.mozzherina',
            twitter: '',
            pinterest: '',
            behance: '',
            linkedin: 'https://www.linkedin.com/in/mozzherina',
            dribbble: '',
            github: 'https://github.com/mozzherina',
        },
        brandImage: '/images/brand-image.jpg',
        aboutImage: '/images/about-image.jpg',
        aboutImageLg: '/images/about-image-lg.jpg',
        cvfile: '/files/CV_Romanenko.pdf',
    },
    services: [
        {
            title: 'Development',
            icon: 'code',
            details: 'Python, Java',
        },
        {
            title: 'Database Design',
            icon: 'database',
            details: 'PostgreSQL, MySQL, ArangoDB, query optimization',
        },
        {
            title: 'Tutoring',
            icon: 'network',
            details:
                'Machine learning, text mining, imperative and declarative programming',
        },
    ],
    reviews: [
        {
            id: 1,
            content:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.',
            author: {
                name: 'Burdette Turner',
                designation: 'Web Developer, Abc Company',
            },
        },
        {
            id: 2,
            content:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.',
            author: {
                name: 'Susan Yost',
                designation: 'Client',
            },
        },
        {
            id: 3,
            content:
                'Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
            author: {
                name: 'Irving Feeney',
                designation: 'Fiverr Client',
            },
        },
    ],
    skills: [
        {
            title: 'HTML5',
            value: 95,
        },
        {
            title: 'CSS3',
            value: 90,
        },
        {
            title: 'Javascript',
            value: 70,
        },
        {
            title: 'jQuery',
            value: 85,
        },
        {
            title: 'ReactJS',
            value: 80,
        },
        {
            title: 'Photoshop',
            value: 65,
        },
    ],
    portfolios: {
        researchProjects: [
            {
                id: 1,
                title: 'Algorithmic Trading System "Cherimoya"',
                featuredImage: '/images/portfolio-image-1.jpg',
                filesource:
                    '../../projects/algorithmic-trading-system-cherimoya.md',
                url: 'https://dribbble.com',
            },
            {
                id: 2,
                title:
                    'Ontology-based classification of large text documents collections ',
                featuredImage: '/images/portfolio-image-2.jpg',
                filesource:
                    '../../projects/ontology-based-classification-of-large-text-documents-collections.md',
                url: 'https://facebook.com',
            },
            {
                id: 3,
                title:
                    'Development of ontology-based applications for Wireless Sensor Networks',
                featuredImage: '/images/portfolio-image-3.jpg',
                filesource:
                    '../../projects/development-of-ontology-based-applications-for-wireless-sensor-networks.md',
                url: 'https://pinterest.com',
            },
        ],
        tutoringProjects: [],
    },
    experience: {
        workingExperience: [
            {
                id: 1,
                year: '2019 - Present',
                position: 'Freelance Software Engineer',
                company: '',
                link: '',
                details:
                    'Database development (PostgreSQL, MySQL), query optimization, backend development',
            },
            {
                id: 2,
                year: '2011–2019',
                position: 'Senior Lecturer',
                company: 'Saint-Petersburg State University, Russia',
                link: 'https://english.spbu.ru/',
                details:
                    'Lectures and labs on courses "Machine Learning", "Data Mining", "Database Design", and "Functional Programming", labs on course "Discrete Mathematics"',
            },
            {
                id: 3,
                year: '06–08/2012',
                position: 'Intern',
                company: 'Microsoft Research Redmond, the USA',
                link: 'https://www.microsoft.com/en-us/research/',
                details: 'MSR Connections Group',
            },
            {
                id: 4,
                year: '2008–2010',
                position: 'Assistant Lecturer',
                company: 'Perm State University, Russia',
                link: 'http://en.psu.ru/',
                details:
                    'Labs on course "Programming Languages", lectures and labs on courses "Pattern Recognition" and "Discrete Mathematics"',
            },
            {
                id: 5,
                year: '2007–2008',
                position: 'Programmer',
                company: 'Informational Computing Systems, Perm, Russia',
                link: 'www.ics.perm.ru',
                details:
                    'Developing windows and web applications using Microsoft VS; testing applications using Rational Robot and n-unit; writing scripts for databases (mainly SQL Server 2005); working with CASE-system "CASEBERRY"',
            },
        ],
        educationExperience: [
            {
                id: 1,
                year: '2008 - 2010',
                graduation:
                    'Master of Science in Computational Science with distinction',
                university: 'University of Reading, the UK',
                link: 'https://www.reading.ac.uk/',
                details:
                    'Title of the dissertation "The Design of the Ontology-basedFramework for Wireless Sensor Network"',
            },
            {
                id: 2,
                year: '2008 - 2010',
                graduation:
                    'Master of Applied Mathematics and Information Science with distinction',
                university: 'Perm State University, Russia',
                link: 'http://en.psu.ru/',
                details:
                    'Title of the dissertation "Development of ontology-based applications for Wireless Sensor Networks"',
            },
            {
                id: 3,
                year: '2004 - 2008',
                graduation:
                    'Bachelor of Applied Mathematics and Information Science with distinction',
                university: 'Perm State University, Russia',
                link: 'http://en.psu.ru/',
                details:
                    'Title of the qualification work "Intellectual Analysis of Structure and Semantics of Electronic Documents based on Ontologies"',
            },
        ],
    },
    blogs: [
        {
            id: 1,
            title: 'Markdown & Html supported blog.',
            featuredImage: '/images/blog-image-1.jpg',
            filesource: '../../blog/markdown-html-supported-blog.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 2,
            title: 'Installing NodeJS on your device.',
            featuredImage: '/images/blog-image-2.jpg',
            filesource: '../../blog/installing-nodejs-on-your-device.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 3,
            title: 'UI/UX design starter with Adobe XD.',
            featuredImage: '/images/blog-image-3.jpg',
            filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 4,
            title: 'Boost your post for increasing sales.',
            featuredImage: '/images/blog-image-4.jpg',
            filesource: '../../blog/boost-your-post-for-increasing-sales.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 5,
            title: 'Difference between GatsbyJS & NextJS.',
            featuredImage: '/images/blog-image-5.jpg',
            filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 6,
            title: 'How to choose javascript framework for project.',
            featuredImage: '/images/blog-image-6.jpg',
            filesource:
                '../../blog/how-to-choose-javascript-framework-for-project.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 7,
            title: 'Web automation with python language.',
            featuredImage: '/images/blog-image-7.jpg',
            filesource: '../../blog/web-automation-with-python-language.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 8,
            title: 'Time to use latest technology for creating a website.',
            featuredImage: '/images/blog-image-8.jpg',
            filesource:
                '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 9,
            title: 'Think out of the box.',
            featuredImage: '/images/blog-image-9.jpg',
            filesource: '../../blog/think-out-of-the-box.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 10,
            title: 'Trending designs in 2020.',
            featuredImage: '/images/blog-image-1.jpg',
            filesource: '../../blog/trending-designs-in-2020.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 11,
            title: 'How to get 10k instagram followers?',
            featuredImage: '/images/blog-image-2.jpg',
            filesource: '../../blog/how-to-get-10k-instagram-followers.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 12,
            title: 'What NodeJS can do?',
            featuredImage: '/images/blog-image-3.jpg',
            filesource: '../../blog/what-nodejs-can-do.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 13,
            title: 'Futures of javascript.',
            featuredImage: '/images/blog-image-4.jpg',
            filesource: '../../blog/future-of-javascript.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 14,
            title: 'Popular javascript library in 2020.',
            featuredImage: '/images/blog-image-5.jpg',
            filesource: '../../blog/popular-javascript-library-in-2020.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
        {
            id: 15,
            title: 'Promrammers must read books.',
            featuredImage: '/images/blog-image-6.jpg',
            filesource: '../../blog/programmers-must-read-books.md',
            createDay: '20',
            createMonth: 'February',
            createYear: '2020',
        },
    ],
    contactInfo: {
        phoneNumbers: [''],
        emailAddress: ['e.mozzherina@hotmail.com'],
        address: 'St.Petersburg, Russia',
    },
};

Mock.onGet('/api/information').reply((config) => {
    const response = database.information;
    return [200, response];
});

Mock.onGet('/api/services').reply((config) => {
    const response = database.services;
    return [200, response];
});

Mock.onGet('/api/reviews').reply((config) => {
    const response = database.reviews;
    return [200, response];
});

Mock.onGet('/api/skills').reply((config) => {
    const response = database.skills;
    return [200, response];
});

Mock.onGet('/api/portfolios').reply((config) => {
    const response = database.portfolios;
    return [200, response];
});

Mock.onGet('/api/experience').reply((config) => {
    const response = database.experience;
    return [200, response];
});

Mock.onGet('/api/blog').reply((config) => {
    const response = database.blogs;
    return [200, response];
});

Mock.onGet('/api/contactinfo').reply((config) => {
    const response = database.contactInfo;
    return [200, response];
});
