import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import axios from 'axios';
import Layout from '../components/Layout';

function ProjectDetails(props) {
    const [content, setContent] = useState('');
    const projectFile = props.match.params.title;
    console.log('I am here (ProjectDetails)');

    useEffect(() => {
        axios
            .get(require(`../projects/${projectFile}.md`))
            .then((result) => {
                setContent(result.data);
            })
            .catch((err) => console.log(err));
    }, [content, projectFile]);

    return (
        <Layout>
            <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <ReactMarkdown
                        source={content}
                        escapeHtml={false}
                    ></ReactMarkdown>
                </div>
            </div>
        </Layout>
    );
}

export default ProjectDetails;
