import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sectiontitle from '../components/Sectiontitle';
import Layout from '../components/Layout';
import PortfoliosView from '../components/PortfoliosView';

function Portfolios() {
    const [researchProjects, setResearchProjects] = useState([]);
    // const [tutoringProjects, setTutoringProjects] = useState([]);

    useEffect(() => {
        axios.get('/api/portfolios').then((response) => {
            setResearchProjects(response.data.researchProjects);
            // setTutoringProjects(response.data.tutoringProjects);
        });
    }, []);

    return (
        <Layout>
            <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
                <div className="container">
                    <Sectiontitle title="Portfolio" />
                    <PortfoliosView portfolios={researchProjects} />
                </div>
            </div>
        </Layout>
    );
}

export default Portfolios;
