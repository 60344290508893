import React from 'react';

function Resume(props) {
    const {
        year,
        position,
        graduation,
        university,
        company,
        link,
        details,
    } = props.resumeData;
    return (
        <div className="mi-resume mt-30">
            <div className="mi-resume-summary">
                <h6 className="mi-resume-year">{year}</h6>
            </div>
            <div className="mi-resume-details">
                <h5>{position || graduation}</h5>
                <a href={link} target="_blank" rel="noreferrer noopener">
                    <h6 className="mi-resume-company">
                        {company || university}
                    </h6>
                </a>
                <p>{details}</p>
            </div>
        </div>
    );
}

export default Resume;
